import { Notyf } from "notyf";

const notyf = new Notyf({
    duration: 4000,
    position: {
        x: 'right',
        y: 'bottom',
    },
});
export default function useNotyf() {
    return {
        success: (message) => {
            notyf.success(message)
        },
        error: (message) => {
            notyf.error(message)
        },
        info: (message) => {
            notyf.open({
                type: 'info',
                message,
            })
        },
        warning: (message) => {
            notyf.open({
                type: 'warning',
                message,
            })
        },
        primary: (message) => {
            notyf.open({
                type: 'primary',
                message,
            })
        }
    }
}

